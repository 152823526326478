import React from "react";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import { StaticImage } from "gatsby-plugin-image";
import { useIntl, Link } from "gatsby-plugin-react-intl";
import Slider from "react-slick";
//import ComponentMain2 from "../../components/main/component-main-2";
//import ComponentTreatmentPnd from "../../components/main/component-treatment-pnd";
//import ComponentTextblock4 from "../../components/component-textblock-4";
import ComponentColumn2 from "../../components/component-column-2";
//import ComponentPics2 from "../../components/pics/component-pics-2";
//import {
//  Accordion,
//  AccordionItem,
//  AccordionItemHeading,
//  AccordionItemButton,
//  AccordionItemPanel,
//} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

const TreatmentCvd = ({ data, location }) => {
  const intl = useIntl();

  //const [isToggleOpen, setToggleOpen] = useState([]);
  //const handleToggle = (e) => {
  //  setToggleOpen({
  //    ...isToggleOpen,
  //    [e.currentTarget.id]: isToggleOpen[e.currentTarget.id] ? false : true,
  //  });
  //};

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    //autoplay: true,
    autoplaySpeed: 4000,
    arrows: false,
    slidesToShow: 1,
    //centerPadding: '100px',
    focusOnSelect: true,
    //centerMode: true,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 896,
        settings: {
          centerMode: false,
        },
      },
    ],
  };

  return (
    <Layout location={location} locale={intl.locale} mode="bls">
      <Seo
        title={
          intl.locale === "en"
            ? `Professional athletes - Technology for Optimal Performance `
            : `Spitzensportler - Technologie für optimale Leistung`
        }
        description={
          intl.locale === "en"
            ? `Use our revolutionary neurofeedback training to develop muscle efficiency and economy and improve neuromuscular system. Maximise your capability!`
            : `Nutzen Sie unser neurologisches Feedbacktraining, um Muskeleffizienz und das neuromuskuläre System zu verbessern. Steigern Sie Ihre Leistungsfähigkeit!`
        }
        ogImage="https://images.ctfassets.net/t2ifj85iosf3/711OZRPldLmWS3sLSgP3nE/05883e641ca99b12837839fc852e3e63/E184.jpg?w=1200&h=630&fit=fill"
      />

      <section className="sect sect_TreatmentCvd_first pt-0">
        <div className="main_back sect-bg-bls"></div>
        <div className="main_copy">
          <h1 className="h1 txt txt-white">
            {intl.locale === "en"
              ? `HAL for Top Athletes`
              : `HAL für Leistungssportler`}
          </h1>
          <p className="message-label-bold txt txt-rot">
            {intl.locale === "en"
              ? `Advance Athletic Performance`
              : `Steigern der sportlichen Leistung`}
          </p>
          <p className="txt txt-white">
            {intl.locale === "en"
              ? `Top athletes across the globe trust Cyberdyne technology to improve
  the performance of their nerve network and muscular systems.`
              : `Spitzenathleten weltweit vertrauen der Cyberdyne Technologie um die Leistungsfähigkeit ihres Nervennetzwerkes und ihrer Muskulatur zu verbessern.`}
          </p>
        </div>
        <div className="main_img">
          <StaticImage
            src="../../images/training/athletes/14.jpg"
            alt="ImgTreatmentCatch"
            className="ImgHeader"
          />
        </div>
      </section>

      <section className="bg-white">
        <div className="container">
          <h2 className="message-lg">
            {intl.locale === "en"
              ? `Athletes are continuously pushing themselves to raise the bar. This
  relentless pursuit of greatness does not come naturally and
  oftentimes, top athletes trust outside tools and technologies to
  continuously improve.`
              : `Athleten motivieren sich selbst ständig, um die Messlatte höher zu legen.  Das unablässliche Streben nach Größe kommt nicht von selbst und oftmals vertrauen Spitzenathleten externen Instrumenten und Technologien, um sich ständig zu verbessern. `}
          </h2>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="flex-row mx-n2 mb-6 pict-3">
            <div className="px-2">
              <StaticImage
                src="../../images/training/athletes/15.jpg"
                className="mb-4"
                alt=""
              />
            </div>
            <div className="px-2">
              <StaticImage
                src="../../images/training/athletes/16.jpg"
                className="mb-4"
                alt=""
              />
            </div>
            <div className="px-2">
              <StaticImage
                src="../../images/training/athletes/17.jpg"
                className="mb-4"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      <section className="sect sect_TreatmentCvd_third bg-white">
        <div className="container">
          <ComponentColumn2
            lefttitle={
              intl.locale === "en"
                ? `Improvements You Can Feel`
                : `Verbesserungen die Sie spüren`
            }
            leftcontent={
              intl.locale === "en"
                ? `For top athletes, HAL can provide multiple types of immediate feedback that can only be experienced through HAL. For instance, the HAL device itself provides physical feedback, reacting with the slightest contraction of extraneous muscles. This may inform the athlete when there is unnecessary muscle stress that can inhibit them from performing at their full potential. As another example, HAL may be used to inform the athlete about how their muscles and body are moving, helping them find the optimal posture or stance for better performance.`
                : `Spitzensportlern bietet der vielfältige und einzigartige direkte Feedbackmöglichkeiten. Zum Beispiel gibt HAL ein physisches Feedback auf die leichteste Kontraktion von für die Bewegung irrelevanten Muskeln. Diese Information kann dem Athleten helfen, unnötige Spannung der Muskulatur zu erkennen, die eine volle Ausschöpfung des Leistungspotential verhindert. Des Weiteren kann HAL genutzt werden, um Athleten darüber zu informieren, wie ihre Muskeln und ihr Körper sich bewegen und dabei helfen die richtige Körperhaltung für eine bessere Leistung zu finden.`
            }
            leftcontent2={
              intl.locale === "en"
                ? `Most importantly, HAL’s ability to display real-time bio-electrical signals through the HAL Monitor helps atheletes understand and practice efficient muscle contraction and relaxation. For top athletes, the ability to control muscle activities efficiently and effectively can greatly escalate performance results.`
                : `Hervorzuheben ist außerdem die Möglichkeit, bioelektrische Signale in Echtzeit auf dem HAL Monitor darzustellen. Das hilft den Sportlern, effiziente Muskelkontraktion und -entspannung zu verstehen und zu trainieren. Die Fähigkeit, Muskelaktivitäten effizient und effektiv zu nutzen, kann die Leistung von Spitzensportlern deutlich verbessern.`
            }
            leftcontent3={
              intl.locale === "en"
                ? `Cyberdyne’s Neuro HAL Plus, currently available only in Japan, is a program designed specifically for athletes in order to improve the performance of the muscular system, as well as adjust the optimal timing and balance of the muscle contraction and relaxation.`
                : `Cyberdynes Neuro HAL Plus, bisher nur in Japan verfügbar, ist ein Angebot das spezifisch für Athleten entwickelt wurde. Es zielt darauf ab die Leistungsfähigkeit der Muskulatur zu verbessern und das optimale Timing und Gleichgewicht von Muskelkontraktion und -relaxation herzustellen.`
            }
            leftcontent4={
              intl.locale === "en"
                ? `Cyberdyne’s HAL suits are the first exoskeletons of their kind to receive a Global Safety Certificate. In addition, they are Europe’s first medical treatment robot of its kind. At Cyberdyne, we’re proud to say that we’re helping athletes advance their agency, confidence, and performance.`
                : `Cyberdynes HAL Anzüge sind die ersten Exoskelette ihrer Art die ein globales Sicherheitszertifikat erhalten haben. Zusätzlich sind der erste europäische Behandlungsroboter seiner Art. Bei Cyberdyne sind wir stolz darauf sagen zu können, dass wir Athleten dabei helfen sich hinsichtlich Selbstvertrauen und Leistungsfähigkeit weiter zu entwickeln.`
            }
            label=""
            list={
              intl.locale === "en"
                ? [
                    "Learn the optimal timing and balance of the muscle contraction and relaxation",
                    "Visualise neuro signals and provide physical feedbacks simultaneously for Top athletes",
                    "Experience performance beyond your normal capacity",
                  ]
                : [
                    "Erlernen Sie das optimale Timing sowie Gleichgewicht von Muskelkontraktion und -entspannung",
                    "Visualisiert neurologische Signale und bietet gleichzeitig ein physisches Feedback für Spitzensportler",
                    "Erfahren Sie eine Leistungsfähigkeit über Ihre Grenzen hinaus",
                  ]
            }
          />
        </div>
      </section>

      <section className="sect position-relative overflow-visible">
        <StaticImage
          src="../../images/training/athletes/treatment-big-teaser@2x.png"
          class=""
        />

        <div className="floating-box">
          <div className="p-8 bg-rot">
            <h2 className="h3">
              {intl.locale === "en"
                ? `Applications on elderly`
                : `Anwendung für ältere Menschen`}
            </h2>
            <p>
              {intl.locale === "en"
                ? `We empower individuals to walk on their own using HAL, our
  non-invasive technology solution. Unlike other mobility therapies
  that simply guide users through the motions by completing
  movements for them, our technology actually retrains the nervous
  system on how to carry out the movement properly`
                : `Wir ermutigen Individuen eigenständig mit dem HAL, unsere nicht-invasive Technologielösung, zu Gehen. Im Gegensatz zu anderen Bewegungstherapien, die den Nutzer nur durch die Bewegung führen in dem sie die Bewegung für den Nutzer ausführen, trainiert unsere Technologie das Nervensystem wie Bewegungen richtig ausgeführt werden sollen.`}
            </p>
            <div>
              <Link
                to="/training/elderly/"
                className="link-border link-border-white"
              >
                {intl.locale === "en" ? `Learn more` : `Erfahren Sie mehr`}
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="sect sect_TreatmentCvd_2f8as2">
        <div className="container">
          <p className="message-label-bold txt txt-rot">
            {intl.locale === "en"
              ? `HAL Lumbar Type in action`
              : `HAL Lumbar Type in Aktion`}
          </p>
          <p className="h3 font-weight-bold">
            {intl.locale === "en"
              ? `Watch the videos to see how HAL is used in training with athletes`
              : `Schauen Sie sich die Videos an und bekommen Sie einen Eindruck wie der HAL mit Sportlern angewendet werden kann`}
          </p>
          <div className="slide">
            <Slider {...settings}>
              <div className="slide__link">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/luYvlzgIDbM"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
                <div className="w560">
                  <p className="message-label-bold">
                    {intl.locale === "en"
                      ? `HAL Lumbar Types gives you feedback if muscles are relaxed`
                      : `HAL Lumbar Type gibt dem Athleten Rückmeldung über dessen Muskelanspannung`}
                  </p>
                </div>
              </div>
              <div className="slide__link">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/a-XnMgxikjE"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
                <div className="w560">
                  <p className="message-label-bold">
                    {intl.locale === "en"
                      ? `Performing lunges with HAL Lumbar Type`
                      : `Ausfallschritte mit dem HAL Lumbar Type`}
                  </p>
                </div>
              </div>
              <div className="slide__link">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/VR30c7HcHC0"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
                <div className="w560">
                  <p className="message-label-bold">
                    {intl.locale === "en"
                      ? `Jumping exercises with HAL Lumbar Type`
                      : `Sprungübungen mit dem HAL Lumbar Type`}
                  </p>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </section>

      <section className="sect sect_TreatmentCvd_2f8as2">
        <div className="container">
          <p className="h3 font-weight-bold">
            {intl.locale === "en"
              ? `Improve Muscle Relaxation With Cyberdyne`
              : `Verbessern Sie die Muskelrelaxation mit Cyberdyne`}
          </p>
          <p>
            {intl.locale === "en"
              ? `Learn how HAL can get you off the bench and back on the field.`
              : `Erfahren Sie wie HAL Sie von der Behandlungsbank zurück auf Spielfeld bringt`}
          </p>
          <div>
            <Link to="/contact/" className="link-button">
              {intl.locale === "en" ? `Get in Touch` : `Kontaktieren Sie uns`}
            </Link>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default TreatmentCvd;
